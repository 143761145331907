<template>
  <div class="exhibition">
    <banner />
    <div class="content_area">
      <div class="about">
        <div class="title">
          {{ $t("globalexpMeeting") }}
        </div>
        <div class="text">
          <div class="left">
            <div class="line">
              <div class="smallTitle">
                {{ $t("AbouttheExpo") }}
              </div>
              <div class="item">
                <div class="key">
                  {{ $t("Exhibitionname") }}{{ $t("colon") }}
                </div>
                <div class="value">
                  {{ $t("globalexpMeeting") }}
                </div>
              </div>
              <div class="item">
                <div class="key">
                  {{ $t("Exhibitiontime") }}{{ $t("colon") }}
                </div>
                <div class="value">
                  {{ $t("exhitibthlisttime") }}
                </div>
              </div>
              <div class="item">
                <div class="key">
                  {{ $t("Exhibitionlocation") }}{{ $t("colon") }}
                </div>
                <div class="value">
                  {{ $t("ExhibitionCenterfor") }}
                </div>
              </div>
              <div class="item">
                <div class="key">
                  {{ $t("Exhibitionmode") }}{{ $t("colon") }}
                </div>
                <div class="value">
                  {{ $t("onlineandonoff") }}
                </div>
              </div>
            </div>
          </div>
          <div class="right" :class="LOCALE">
            <div class="content">
              <div class="one ti2">
                <span> {{ $t("globalexpMeeting") }}, </span
                ><span class="fw600"
                  >“{{ $t("myExhibitionsummary_p15") }}”</span
                >
                {{ $t("myExhibitionsummary_p1") }}
              </div>
              <div class="two">
                {{ $t("myExhibitionsummary_p2") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Sponsor">
        <div class="title">
          {{ $t("Sponsorintroduction") }}
        </div>
        <div class="content">
          <div class="left">
            <div class="img">
              <el-image
                style="width: 346px; height: 113px"
                :src="url2"
                fit="scale-down"
              ></el-image>
            </div>
            <div class="name">
              {{ $t("jcLogisticsnetwork") }}
            </div>
            <div class="text">
              <div class="one ti2">
                {{ $t("myExhibitionsummary_p3") }}
              </div>
              <div class="two ti2">
                {{ $t("myExhibitionsummary_p4") }}
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="right">
            <div class="img">
              <el-image
                style="width: 346px; height: 113px"
                :src="url3"
                fit="scale-down"
              ></el-image>
            </div>
            <div class="name">
              {{ $t("ailacompany") }}
            </div>
            <div class="text ti2">
              {{ $t("myExhibitionsummary_p5") }}
            </div>
          </div>
        </div>
      </div>
      <div class="xhibition">
        <div class="title">
          {{ $t("Exhibitionvenue") }}
        </div>
        <div class="content">
          <div class="left" :class="LOCALE">
            <div class="one ti2">
              {{ $t("myExhibitionsummary_p6") }}
            </div>
            <div class="two ti2">
              {{ $t("myExhibitionsummary_p7") }}
            </div>
          </div>
          <div class="right">
            <el-image
              style="width: 882px; height: 474px"
              :src="url4"
              fit="scale-down"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "~exp/components/common/banner";

export default {
  name: "exhibitionSummary",
  components: {
    banner,
  },
  data() {
    return {
      url1:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/exhibition/exhibition01.png",
      url2:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/exhibition/exhibition02.png",
      url3:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/exhibition/exhibition03.png",
      url4:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/exhibition/exhibition04.png",
    };
  },
};
</script>

<style scoped lang="less">
.exhibition {
  padding-bottom: 70px;
}

.title {
  font-size: 24px;
  font-weight: 800;
  color: #333333;
  text-align: center;
  margin-bottom: 25px;
}

.about {
  padding-top: 30px;
  margin-bottom: 60px;

  .text {
    width: 100%;
    height: 362px;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/exhibition/exhibition01.png") no-repeat;
    display: flex;

    .left {
      width: 440px;
      padding: 32px 0 32px 49px;

      .line {
        width: 100%;
        height: 100%;
        border-right: 1px dashed #c8cdd0;

        .smallTitle {
          font-size: 18px;
          font-weight: 800;
          color: #333333;
          margin-bottom: 30px;
        }

        .item {
          display: flex;
          margin-bottom: 25px;

          .key {
            font-size: 18px;
            font-weight: 400;
            color: #7d8695;
          }

          .value {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
          }
        }
      }
    }

    .right {
      flex: 1;
      padding: 50px 52px 50px 58px;

      .content {
        font-size: 16px;
        font-weight: 400;
        color: #7d7d7d;

        .one {
          margin-bottom: 15px;
        }

        .two {
          text-indent: 2em;
        }
      }
      &.en {
        padding: 2px 5px 0 5px;
        .one {
          margin-bottom: 0;
        }
      }
    }
  }
}

.Sponsor {
  margin-bottom: 60px;

  .content {
    display: flex;
  }

  .img {
    text-align: center;
    margin-bottom: 16px;
  }

  .name {
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    color: #333333;
    margin-bottom: 24px;
  }

  .left {
    width: 649px;
    padding-right: 90px;

    .one {
      margin-bottom: 10px;
    }
  }

  .line {
    width: 1px;
    height: 310px;
    background: linear-gradient(
      180deg,
      rgba(238, 238, 238, 0) 0%,
      #d8d8d8 51%,
      rgba(216, 216, 216, 0) 100%
    );
  }

  .right {
    padding-left: 89px;
    flex: 1;
  }
}

.xhibition {
  .content {
    text-align: right;
    position: relative;

    .left {
      position: absolute;
      left: 0;
      top: 43px;
      width: 475px;
      height: 389px;
      background: #f9fafa;
      z-index: 5;
      padding: 74px 42px;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      text-align: left;
      &.en {
        padding: 8px 0 0 0;
      }

      .one {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
