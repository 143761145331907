<template>
  <div class="containerBox">
    <div class="bannerContent">
      <div
          class="bannerInner"
          :style="{ 'background-image': 'url(' + bannerImg + ')' }"
      >
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "banner",
    data() {
      return {
        img: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/banner.png',
        img_en: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/banner_en.png',
      }
    },
    computed: {
      bannerImg() {
        return this.LOCALE == 'en' ?  this.img_en : this.img
      }
    }
  }
</script>

<style scoped lang="less">
.containerBox {
  width: 100%;
  padding-top: 18.23%;
  position: relative;
  .bannerContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    .bannerInner {
      height: 100%;
      background-size: 100% 100%;
      position: relative;
    }
  }
  .img {
    width: 100%;
  }
}
</style>